import * as React from "react";
import { Link } from "gatsby";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import Accordion from "@mui/material/Accordion";
import AccordionDetails from "@mui/material/AccordionDetails";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import "./index.scss";

interface ServicesProps {
  contentServices?: any;
  dark?: boolean;
}

const Services: React.FC<ServicesProps> = ({
  contentServices,
  dark = true,
}) => {
  return (
    <section className={dark ? "services" : "services light"}>
      <div className="container">
        <h3>{contentServices.subTitle}</h3>
        <h2>{contentServices.title}</h2>
        <div className="services-list">
          {contentServices?.listServices.map((e: any, i: any) => {
            return (
              <Accordion
                key={i}
                className={i === 0 ? "service-item open" : "service-item"}
                disableGutters={true}
                defaultExpanded={i === 0}
              >
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls={`service-content-${i}`}
                  id={`service-content-${i}`}
                >
                  <div className="service-item-head">
                    {e.icon && (
                      <img
                        src={`${process.env.PUBLIC_URL}/img/icons/${e.icon}.svg`}
                        alt={e.name}
                      />
                    )}
                    <h4>{e.name}</h4>
                  </div>
                </AccordionSummary>
                <AccordionDetails>
                  <p>{e.content}</p>
                  <div className="service-link">
                    <nav>
                      <ul>
                        {e?.subServices?.slice(0, 10).map((e: any, i: any) => {
                          return (
                            <li key={i}>
                              <Link to={e.slug}>
                                {e.name} <ArrowRightAltIcon />
                              </Link>
                            </li>
                          );
                        })}
                      </ul>
                      <ul>
                        <li className="view-more-services">
                          <Link to={e.link}>
                            More {e.name} Services <ArrowRightAltIcon />
                          </Link>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </div>
        <p className="service-desc">{contentServices.content}</p>
      </div>
    </section>
  );
};

export default Services;
