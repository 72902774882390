import * as React from "react";
import { graphql } from "gatsby";
import type { HeadFC } from "gatsby";
import { getImage } from "gatsby-plugin-image";
import useCookieState from "../hooks/useCookieState";
import usePageData from "../hooks/usePageData";
import { PropsType } from "../hooks/propsType";
import Seo from "../components/SEO";
import Header from "../components/Header";
import Footer from "../components/Footer";
import Hero from "../components/Hero";
import Brands from "../components/Brands";
import Insights from "../components/Insights";
import Services from "../components/Services";
import HeroHigh from "../components/HeroHigh";
import AboutProcess from "../components/AboutProcess";
import { transformedData } from "../utils/functions";
import "../assets/scss/index.scss";

const Page: React.FC<PropsType> = ({ data, pageContext }) => {
  const [compareList, setCompareList] = useCookieState(
    "compare",
    JSON.stringify([])
  );
  const [openModal, setOpenModal] = React.useState(false);

  const { settings, articles, products, curentPage } = usePageData(
    data,
    pageContext
  );

  return (
    <div className="tf">
      <Header
        settings={transformedData(settings?.header)}
        settingsFooter={transformedData(settings?.footer)}
        products={products}
        compareList={compareList}
        setCompareList={setCompareList}
        openModal={openModal}
        setOpenModal={setOpenModal}
      />
      <Hero
        settings={{
          ...transformedData(curentPage?.metadata),
          resultsNotFound: transformedData(settings?.header)?.resultsNotFound,
        }}
        descriptionShort={false}
        short={false}
        search={true}
        award={2}
        products={products}
        placeHolder={transformedData(settings?.header)?.searchPlaceholder}
      />
      <Services
        dark={false}
        contentServices={{
          subTitle: transformedData(settings?.services)?.subTitle,
          title: transformedData(settings?.services)?.title,
          content: transformedData(settings?.services)?.content,
          listServices: transformedData(settings?.header)
            ?.menu?.slice(0, 9)
            .map((e: any) => {
              return {
                icon: e?.icon,
                link: e?.link,
                name: e?.text,
                content: e?.titleCategory,
                subServices: products
                  ?.filter((f: any) => "/" + f.category === e?.link)
                  .map((e: any) => {
                    return {
                      name: e?.keyword,
                      slug: `/${`Best ${e?.keyword} in ${e?.city}`
                        .toLowerCase()
                        .replace(/[^a-zA-Z0-9 ]/g, "")
                        .replace(/ /g, "-")}`,
                    };
                  }),
              };
            }),
        }}
      />
      <HeroHigh settings={transformedData(settings?.heroInside)} />
      <AboutProcess settings={transformedData(settings?.aboutProcess)} />
      <Brands settings={transformedData(settings?.awards)} light={false} />
      <Insights
        articles={articles?.slice(0, 3)}
        full={false}
        light={true}
        settings={transformedData(settings?.news)}
      />
      <Footer settings={transformedData(settings?.footer)} />
    </div>
  );
};

export const Head: HeadFC<PropsType> = ({ data, pageContext }) => {
  const { curentPage, settings } = usePageData(data, pageContext);
  const metaData = transformedData(curentPage?.metadata);
  const image = getImage(
    transformedData(curentPage?.metadata).images[0]?.heroImage
  );
  return (
    <Seo
      title={metaData?.seoTitle}
      description={metaData?.seoDescription}
      image={image?.images?.fallback?.src}
      url={settings?.slug}
      schema={JSON.stringify({
        "@context": "https://schema.org",
        "@type": "WebSite",
        name: process.env.NAME,
        url: process.env.PUBLIC_URL,
        image: (process.env.PUBLIC_URL || "") + image?.images?.fallback?.src,
        headline: metaData?.seoTitle,
      })}
    />
  );
};

export const query = graphql`
  query {
    allMdx(sort: { frontmatter: { date: DESC } }) {
      nodes {
        ...MdxFields
      }
    }
    allFirestoreData {
      nodes {
        keyword
        city
        display
        category
        description
        fqa {
          desc
          title
        }
        id
        places {
          vicinity
          title
          ratings
          rating
          place_id
          display
          description
          customersSay
          formatted_address
          formatted_phone_number
          photoUrl
          website
        }
        seoDescription
      }
    }
  }
`;

export default Page;
